import React from "react"
import { Link } from "gatsby"
import { ParallaxBanner } from "react-scroll-parallax"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Wrapper from "../components/wrapper"

const SecondPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <ParallaxBanner
      className="your-class"
      layers={[
        {
          image:
            "https://images.unsplash.com/photo-1564049489314-60d154ff107d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2561&q=80",
          amount: 0.2,
        },
      ]}
      style={{
        height: "200px",
      }}
    ></ParallaxBanner>
    <Wrapper padding>
      <h2 style={{ textAlign: "center", fontWeight: 500, width: "100%" }}>
        Impressum
      </h2>
      <ul class="portfolioPrev">
        <br />
        <br />
        Brain Offices GmbH <br />
        Mühlenstr. 8 a <br />
        D-14167 Berlin <br />
        Deutschland
        <br />
        <br />
        Amtsgericht Charlottenburg (Berlin)
        <br />
        HRB 167192 B
        <br />
        <br />
        Ust-ID:DE303477005
        <br />
        <br />
        CEO Sascha Ohff
      </ul>
    </Wrapper>
  </Layout>
)

export default SecondPage
